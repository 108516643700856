<template>
  <div>
    <b-tabs pills small nav-class="nav-users" v-if="!is_mobile">
      <b-tab
        @click="handleSelectProfile(menu)"
        :variant="selectedProfile === menu ? 'primary' : 'light'"
        v-for="(menu, index) in summary"
        :key="index"
        :title="`${menu.label}  (${menu.total})`"
      >
      </b-tab>
    </b-tabs>
    <div class="d-flex justify-content-between">
      <div class="input-search mb-4">
        <b-form-input
          autocomplete="off"
          @input="handleSearch"
          v-model="search"
          placeholder="Digite para pesquisar"
          class="col-3 mb-2"
        />
        <b-button
          v-if="!isBusy"
          variant="text"
          class="px-2 d-flex align-items-center absolute icon-action"
        >
          <b-icon icon="search" />
        </b-button>
      </div>

      <b-dropdown
        v-if="is_mobile"
        dropleft
        no-caret
        variant="text"
        toggle-class="btn-filter"
        menu-class="shadow"
        class="ml-2"
      >
        <template #button-content>
          <b-icon icon="filter" />
        </template>
        <b-dropdown-item-button
          :active="selectedProfile.profile_id === menu.profile_id"
          v-for="(menu, index) in summary"
          @click="handleSelectProfile(menu)"
          :key="index"
        >
          {{ `${menu.label}  (${menu.total})` }}
        </b-dropdown-item-button>
      </b-dropdown>

      <b-button
        variant="secondary"
        @click="handleAddNewUser()"
        class="btn-add"
        v-if="isMaatUser()"
      >
        Adicionar {{ selectedProfile.label }}
      </b-button>
    </div>
    <p v-if="selectedProfile && is_mobile">
      Filtro: <b>{{ selectedProfile.label || "Todos" }} </b>
    </p>

    <empty-list
      v-if="items && items.length === 0 && !isBusy"
      :text="`Não há usuários com o perfil: ${selectedProfile.label}`"
    />
    <skeleton height="198px" rows="8" grid="5" gap="8" v-if="isBusy" />
    <div v-else>
      <cards-list grid="5" gap="12">
        <template v-for="(item, index) in items">
          <b-card
            :key="index"
            class="card-hoverable pointer"
            :border-variant="item.id === user.id ? 'primary' : ''"
          >
            <b-badge
              variant="success"
              v-if="item.is_online"
              class="current-user"
            >
              Online
            </b-badge>
            <router-link :to="`/users/detail/${item.id}`" class="text-muted">
              <section class="d-md-block">
                <section class="d-flex align-items-center mb-3 gap-2">
                  <avatar
                    :photo="item.photo"
                    :is_online="item.is_online"
                    :name="item.full_name"
                  />
                  <div>
                    <strong class="d-block w-100">
                      {{ item.full_name }}
                    </strong>
                  </div>
                </section>
                <small class="d-block">
                  <strong>E-mail:</strong> {{ item.email }}
                </small>
                <small class="d-block">
                  <strong>Posição:</strong> {{ item.profile.name }}
                </small>
                <small v-if="item && item.enterprise" class="d-block">
                  <strong>Empresa:</strong>
                  {{ item.enterprise.social_reason }}
                </small>
                <small class="d-block">
                  <strong>Função:</strong>
                  {{ getNameRole(item) }}
                </small>
              </section>
            </router-link>
          </b-card>
        </template>
      </cards-list>
      <b-pagination
        @change="handleChangePage"
        v-model="current_page"
        :total-rows="total"
        :per-page="per_page"
        aria-controls="my-table"
      />
    </div>

    <b-modal
      id="add-user"
      centered
      hide-footer
      size="md"
      no-close-on-backdrop
      :title="
        `${isAdd ? 'Adicionar' : 'Editar'} usuário para ${
          selectedProfile.label
        }`
      "
    >
      <Form
        :id="selectedId"
        :profile="selectedProfile"
        :isAdd="isAdd"
        :permissions="summary"
        @submited="handleCloseModal"
      />
    </b-modal>
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapActions, mapGetters } from "vuex";
import Form from "./form";

export default {
  mixins: [listPaginated],

  components: {
    Form,
  },

  data() {
    return {
      params: {},
      action_name: "user",
      selectedProfile: {},
      selectedId: null,
      isAdd: false,
    };
  },
  computed: {
    ...mapGetters(["user", "is_mobile", "is_preview"]),
  },

  methods: {
    ...mapActions(["get_all_profile", "toggle_provider"]),

    isMaatUser() {
      if (!this.user.is_admin && this.selectedProfile?.profile_id) {
        return true;
      }

      const id = this.selectedProfile?.profile_id;

      const ADMIN_MAAT_PROFILE = 1;
      const OPERATIONAL_MAAT_PROFILE = 2;
      const LEGALPERSONNEL_MAAT_PROFILE = 4;

      return [
        ADMIN_MAAT_PROFILE,
        OPERATIONAL_MAAT_PROFILE,
        LEGALPERSONNEL_MAAT_PROFILE,
      ].includes(id);
    },

    handleAddNewUser() {
      if (!this.selectedProfile.profile_id) {
        this.selectedProfile = this.summary[1];
      }

      this.isAdd = true;
      this.$bvModal.show("add-user");
    },

    handleSelectProfile(profile) {
      this.params.profile_id = profile.profile_id;
      this.selectedProfile = profile;
      this.search = null;

      if (!profile.profile_id) {
        delete this.params.profile_id;
      }
      this.getList();
    },
    handleCloseModal() {
      this.$bvModal.hide("add-user");
      this.getList();
    },

    getNameRole(user) {
      if (user.is_admin) {
        return "Adminstrador";
      }
      return {
        borrower: "Tomador",
        provider: "Prestador",
        maat: "Maat Backoffice",
      }[user.role];
    },
  },

  watch: {
    summary: {
      handler(menus) {
        if (menus && !this.selectedProfile) {
          this.selectedProfile = menus[0];
        }
      },
      deep: true,
    },
    is_preview: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.current-user {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
</style>
