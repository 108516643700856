<template>
  <div>
    <b-form @submit.prevent="handleSubmitCredentials()">
      <b-form-group :label="$t('general.name')">
        <b-form-input
          v-model="$v.full_name.$model"
          :class="$v.full_name.$error && 'is-invalid'"
          trim
        />
        <b-form-invalid-feedback v-if="$v.full_name.$error">
          Digite um nome válido
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('general.email')">
        <b-form-input
          placeholder="email@email.com"
          v-model="$v.email.$model"
          :class="$v.email.$error && 'is-invalid'"
          trim
        />
        <b-form-invalid-feedback v-if="!$v.email.email || !$v.email.required">
          {{ $t("auth.type-valid-email") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button size="sm" variant="link" @click="$emit('submited')">
          Cancelar
        </b-button>
        <b-button
          size="sm"
          type="submit"
          variant="primary"
          :disabled="isLoading"
          class="pull-right text-right"
        >
          <b-spinner v-if="isLoading" small class="mr-2" />
          <span> {{ $t("general.save") }}</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  props: {
    id: {
      type: [String, Number],
    },
    profile: {
      type: Object,
    },
    current_enterprise: {
      type: Object,
    },
    isAdd: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      full_name: null,
      email: null,
      profileId: "",
      enterprise: null,
      admin: false,
      enterprises: [],
    };
  },
  validations: {
    full_name: {
      required,
      minLength: minLength(4),
    },

    email: {
      required,
      email,
    },
    enterprise: {
      required: false,
    },
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    ...mapActions(["add_user", "get_user_by_id", "show_toast", "edit_user"]),
    handleSubmitCredentials() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$anyError) {
        if (this.isAdd) {
          this.saveUser();
        } else {
          this.editUser();
        }
      }
    },
    saveUser() {
      this.add_user({
        full_name: this.full_name,
        email: this.email,
        profile_id: this.profile.profile_id,
        enterprise: this.user.enterprise_id,
      })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Cadastro realizado com sucesso",
              type: "success",
            });
            this.$emit("submited");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.show_toast({
            message: err,
            type: "error",
          });
        });
    },
    editUser() {
      this.edit_user({
        full_name: this.full_name,
        email: this.email,
        id: this.id,
        profile_id: this.profile.profile_id,
        enterprise: this.user.enterprise_id,
      })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Edição realizada com sucesso",
              type: "success",
            });
            this.$emit("submited");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.show_toast({
            message: err,
            type: "error",
          });
        });
    },
    getEdit() {
      this.get_user_by_id({ id: this.id }).then(({ full_name, email }) => {
        this.full_name = full_name;
        this.email = email;
      });
    },
  },

  mounted() {
    if (!this.isAdd) {
      this.getEdit();
    }
  },
};
</script>
